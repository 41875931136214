import './SettingsComplianceDetails.less'

import { Button, Form, InputNumber, Switch } from 'antd'
import { navigate } from 'gatsby-plugin-react-intl'
import React, { useContext, useEffect, useState } from 'react'

import { useScopedIntl } from '../../../hooks'
import {
  AclAction,
  AclFeature,
  ComplianceData,
  fetchComplianceData as fetchComplianceDataRequest,
  updateComplianceData
} from '../../../requests'
import { routes } from '../../../routes'
import { maxTitleLengthLong, validateRequired } from '../../../validation'
import { UserContext } from '../../auth'
import { DatacEmailSelect, DatacFormItem, DatacLoading, DatacMessage, DatacTitle } from '../../common'
import { SettingsLayout } from '../SettingsLayout'

const ComplianceSettingsBox: React.FC<{ title: string; isLoading: boolean }> = ({ children, title, isLoading }) => (
  <div className="compliance-content__box">
    <DatacTitle size="small">{title}</DatacTitle>
    <DatacLoading isLoading={isLoading}>
      <div className="compliance-content__box__body">{children}</div>
    </DatacLoading>
  </div>
)

export const SettingsComplianceDetails: React.FC = () => {
  const intl = useScopedIntl('')
  const intlCompliance = useScopedIntl('settings.compliance')
  const intlPrivacy = useScopedIntl('settings.privacy')
  const { user } = useContext(UserContext)
  const [isEditingOn, setIsEditingOn] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [isSubjectAccountDeletionEnabled, setIsSubjectAccountDeletionEnabled] = useState(false)
  const [isDataRetentionEnabled, setIsDataRetentionEnabled] = useState(false)
  const [isDeletingPendingSubjectEnabled, setIsDeletingPendingSubjectEnabled] = useState(false)
  const [isSetSubjectInactiveEnabled, setIsSetSubjectInactiveEnabled] = useState(false)
  const [emailRecipients, setEmailRecipients] = useState<string[]>([])
  const [form] = Form.useForm()

  useEffect(() => {
    fetchComplianceData()
  }, [])

  const fetchComplianceData = () => {
    if (!user.canDo(AclFeature.GeneralSettings)(AclAction.Edit)) return

    fetchComplianceDataRequest({
      onSuccess: (data: ComplianceData) => {
        form.setFieldsValue(data)
        setIsSubjectAccountDeletionEnabled(!!data.subjectAccountDeletionEmails?.length)
        setIsDataRetentionEnabled(!!data.subjectRetentionMonths)
        setIsDeletingPendingSubjectEnabled(!!data.pendingSubjectRetentionDays)
        setIsSetSubjectInactiveEnabled(!!data.subjectSetInactiveMonths)
        setEmailRecipients(data.subjectAccountDeletionEmails || [])
        setIsLoading(false)
        setIsEditingOn(false)
      },
      onRequestError: code => {
        DatacMessage.genericError(intl, code)
        setIsLoading(false)
      }
    })
  }

  const onSave = () => form.submit()

  const onSubmit = ({
    subjectAccountDeletionEmails,
    subjectRetentionMonths,
    consentExtensionDay,
    pendingSubjectRetentionDays,
    subjectSetInactiveMonths,
    inactiveSubjectRetentionDays
  }: Partial<ComplianceData>) => {
    setIsSaving(true)
    updateComplianceData(
      {
        subjectAccountDeletionEmails: subjectAccountDeletionEmails || [],
        subjectRetentionMonths: subjectRetentionMonths || null,
        consentExtensionDay: consentExtensionDay || null,
        pendingSubjectRetentionDays: pendingSubjectRetentionDays || null,
        subjectSetInactiveMonths: subjectSetInactiveMonths || null,
        inactiveSubjectRetentionDays: inactiveSubjectRetentionDays || null
      },
      {
        onSuccess: () => {
          setIsEditingOn(false)
          setIsSaving(false)
        },
        onRequestError: code => DatacMessage.genericError(intl, code)
      }
    )
  }

  const areComplianceSettingsEnabled =
    user.canDo(AclFeature.GeneralSettings)(AclAction.Edit) && user.isSubjectRepositoryEnabled

  return (
    <SettingsLayout>
      <div className="compliance-content">
        <div className="compliance-content__header">
          <div>
            <DatacTitle>{intlCompliance('title')}</DatacTitle>
            <div className="compliance-content__header__description">{intlCompliance('description')}</div>
          </div>
          {areComplianceSettingsEnabled && (
            <div className="compliance-content__header__controls">
              {!isEditingOn && (
                <Button type="primary" size="large" onClick={() => setIsEditingOn(true)}>
                  {intl('common.edit')}
                </Button>
              )}
              {isEditingOn && (
                <>
                  <Button type="default" size="large" onClick={fetchComplianceData}>
                    {intl('common.cancel')}
                  </Button>
                  <Button type="primary" size="large" onClick={onSave} loading={isSaving}>
                    {intl('common.save')}
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
        <ComplianceSettingsBox title={intlCompliance('privacy_policy')} isLoading={false}>
          <div
            className="compliance-content__row  compliance-content__row--clickable"
            onClick={() => navigate(routes.settingsPrivacy)}
          >
            <div>
              <DatacTitle size="small">{intlPrivacy('title')}</DatacTitle>
              <div className="compliance-content__row__description">{intlPrivacy('description')}</div>
            </div>
            <div className="compliance-content__row__arrow">{'>'}</div>
          </div>
        </ComplianceSettingsBox>
        {areComplianceSettingsEnabled && (
          <Form form={form} onFinish={onSubmit} name="settings-users-edit-form">
            <ComplianceSettingsBox title={intlCompliance('data_retention_rules')} isLoading={isLoading}>
              <div className="compliance-content__row">
                <div>
                  <DatacTitle size="small">{intlCompliance('request_account_deletion.title')}</DatacTitle>
                  <div className="compliance-content__row__description">
                    {intlCompliance('request_account_deletion.description')}
                  </div>
                </div>
                <div>
                  <Switch
                    disabled={!isEditingOn}
                    checked={isSubjectAccountDeletionEnabled}
                    onChange={setIsSubjectAccountDeletionEnabled}
                  />
                </div>
              </div>
              {isSubjectAccountDeletionEnabled && (
                <div className="compliance-content__row  compliance-content__row--email">
                  <div className="compliance-content__row__left">
                    <DatacTitle size="small">{intlCompliance('notifications_emails.title')}</DatacTitle>
                    <br />
                  </div>
                  <div>
                    <DatacEmailSelect
                      name="subjectAccountDeletionEmails"
                      value={emailRecipients}
                      onChange={setEmailRecipients}
                      className="compliance-content__row__input"
                      form={form}
                      disabled={!isEditingOn}
                    />
                  </div>
                </div>
              )}
              <div className="compliance-content__row">
                <div>
                  <DatacTitle size="small">{intlCompliance('data_retention_enabled.title')}</DatacTitle>
                  <br />
                </div>
                <div>
                  <Switch
                    disabled={!isEditingOn}
                    checked={isDataRetentionEnabled}
                    onChange={setIsDataRetentionEnabled}
                  />
                </div>
              </div>
              {isDataRetentionEnabled && (
                <div className="compliance-content__row">
                  <div>
                    <DatacTitle size="small">{intlCompliance('retention_period.title')}</DatacTitle>
                    <div className="compliance-content__row__description">
                      {intlCompliance('retention_period.description')}
                    </div>
                  </div>
                  <div>
                    <DatacFormItem
                      label=" "
                      name="subjectRetentionMonths"
                      className="compliance-content__row__input"
                      validate={validateRequired(intl('common.required'))}
                    >
                      <InputNumber
                        size="large"
                        maxLength={maxTitleLengthLong}
                        placeholder={intlCompliance('period.placeholder')}
                        min={1}
                        step={1}
                        disabled={!isEditingOn}
                      />
                    </DatacFormItem>
                  </div>
                  <div className="compliance-content__row__period-type">{intlCompliance('period.months')}</div>
                </div>
              )}
              {isDataRetentionEnabled && (
                <div className="compliance-content__row  compliance-content__row--email">
                  <div>
                    <DatacTitle size="small">{intlCompliance('consent_extension_window.title')}</DatacTitle>
                    <div className="compliance-content__row__description">
                      {intlCompliance('consent_extension_window.description')}
                    </div>
                  </div>
                  <div>
                    <DatacFormItem
                      label=" "
                      name="consentExtensionDay"
                      className="compliance-content__row__input"
                      validate={validateRequired(intl('common.required'))}
                    >
                      <InputNumber
                        size="large"
                        maxLength={maxTitleLengthLong}
                        placeholder={intlCompliance('period.placeholder')}
                        min={1}
                        step={1}
                        max={150}
                        disabled={!isEditingOn}
                      />
                    </DatacFormItem>
                  </div>
                  <div className="compliance-content__row__period-type">{intlCompliance('period.days')}</div>
                </div>
              )}
            </ComplianceSettingsBox>
            <ComplianceSettingsBox title={intlCompliance('data_retention_automation')} isLoading={isLoading}>
              <div className="compliance-content__row">
                <div>
                  <DatacTitle size="small">{intlCompliance('delete_pending_enabled.title')}</DatacTitle>
                  <br />
                </div>
                <div>
                  <Switch
                    disabled={!isEditingOn}
                    checked={isDeletingPendingSubjectEnabled}
                    onChange={setIsDeletingPendingSubjectEnabled}
                  />
                </div>
              </div>
              {isDeletingPendingSubjectEnabled && (
                <div className="compliance-content__row">
                  <div>
                    <DatacTitle size="small">{intlCompliance('delete_pending_period.title')}</DatacTitle>
                    <div className="compliance-content__row__description">
                      {intlCompliance('delete_pending_period.description')}
                    </div>
                  </div>
                  <div>
                    <DatacFormItem
                      label=" "
                      name="pendingSubjectRetentionDays"
                      className="compliance-content__row__input"
                      validate={validateRequired(intl('common.required'))}
                    >
                      <InputNumber
                        size="large"
                        maxLength={maxTitleLengthLong}
                        placeholder={intlCompliance('period.placeholder')}
                        min={1}
                        step={1}
                        disabled={!isEditingOn}
                      />
                    </DatacFormItem>
                  </div>
                  <div className="compliance-content__row__period-type">{intlCompliance('period.days')}</div>
                </div>
              )}
              <div className="compliance-content__row">
                <div>
                  <DatacTitle size="small">{intlCompliance('set_subject_inactive.title')}</DatacTitle>
                  <div className="compliance-content__row__description">
                    {intlCompliance('set_subject_inactive.description')}
                  </div>
                </div>
                <div>
                  <Switch
                    disabled={!isEditingOn}
                    checked={isSetSubjectInactiveEnabled}
                    onChange={setIsSetSubjectInactiveEnabled}
                  />
                </div>
              </div>
              {isSetSubjectInactiveEnabled && (
                <div className="compliance-content__row  compliance-content__row--email">
                  <div>
                    <DatacTitle size="small">{intlCompliance('subject_inactive_period.title')}</DatacTitle>
                    <br />
                  </div>
                  <div>
                    <DatacFormItem
                      label=" "
                      name="subjectSetInactiveMonths"
                      className="compliance-content__row__input"
                      validate={validateRequired(intl('common.required'))}
                    >
                      <InputNumber
                        size="large"
                        maxLength={maxTitleLengthLong}
                        placeholder={intlCompliance('period.placeholder')}
                        min={1}
                        step={1}
                        disabled={!isEditingOn}
                      />
                    </DatacFormItem>
                  </div>
                  <div className="compliance-content__row__period-type">{intlCompliance('period.months')}</div>
                </div>
              )}
              {isSetSubjectInactiveEnabled && (
                <div className="compliance-content__row">
                  <div>
                    <DatacTitle size="small">{intlCompliance('inactive_subject_retention_period.title')}</DatacTitle>
                    <div className="compliance-content__row__description">
                      {intlCompliance('inactive_subject_retention_period.description')}
                    </div>
                  </div>
                  <div>
                    <DatacFormItem
                      label=" "
                      name="inactiveSubjectRetentionDays"
                      className="compliance-content__row__input"
                      validate={validateRequired(intl('common.required'))}
                    >
                      <InputNumber
                        size="large"
                        maxLength={maxTitleLengthLong}
                        placeholder={intlCompliance('period.placeholder')}
                        min={1}
                        step={1}
                        disabled={!isEditingOn}
                      />
                    </DatacFormItem>
                  </div>
                  <div className="compliance-content__row__period-type">{intlCompliance('period.days')}</div>
                </div>
              )}
            </ComplianceSettingsBox>
          </Form>
        )}
      </div>
    </SettingsLayout>
  )
}
